import React, { useRef } from "react";

import Meta from "components/Meta";
import Layout from "components/Layout";
import LoadMoreButton from "components/LoadMoreButton";
import * as Hero from "components/Hero";
import { HeroColour, HeroSize } from "components/Hero/Banner";
import * as ACP from "components/ACP";
import { ACPTemplateProps } from "../types";
import NoResultsMessage from "components/NoResultsMessage";

const Template: React.FC<ACPTemplateProps> = ({
  acpEntries,
  bestPractice,
  defaultMeta,
  errorMessage,
  filterProps,
  handleLoadMoreClick,
  hero_banner,
  isLoading,
  location,
  meta,
  resultsRef,
  skeleton,
  totalRecords,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const showLoadMoreButton = acpEntries && totalRecords !== 0 && totalRecords !== acpEntries.length;

  return (
    <Layout>
      <Meta defaultMeta={defaultMeta} location={location} meta={meta} />
      <div>
        {hero_banner && (
          <Hero.Banner
            background_colour={HeroColour.BLACK_GRAPE}
            content={hero_banner.content}
            forwardedContainerRef={containerRef}
            hero_image={hero_banner?.image}
            hero_size={HeroSize.LARGE}
            image_horizontal_position={hero_banner.image_horizontal_position}
            overlay={true}
            reverseDivider={true}
          >
            <ACP.Filter
              bestPractice={bestPractice}
              forwardedContainerRef={containerRef}
              isLoading={isLoading}
              {...filterProps}
            />
          </Hero.Banner>
        )}
        <div className="scrollContainer" ref={resultsRef}>
          {skeleton && <ACP.Skeleton />}
          {totalRecords === 0 ? (
            <NoResultsMessage errorMessage={errorMessage} />
          ) : (
            !skeleton && <ACP.Grid bestPractice={bestPractice} entries={acpEntries} />
          )}
          {totalRecords !== null && showLoadMoreButton && (
            <LoadMoreButton isLoading={isLoading} handleClick={handleLoadMoreClick} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Template;
