import React from "react";

import { OrganizationsItemType } from "modules/api/endpoints/organizations";
import { BestPracticeType } from "../types";
import * as ACP from "components/ACP";

import * as styles from "./styles.module.scss";

export type ACPGridProps = {
  bestPractice: BestPracticeType[];
  entries: OrganizationsItemType[] | null;
};

const Grid: React.FC<ACPGridProps> = ({ bestPractice, entries }) => {
  return entries && entries.length > 0 ? (
    <div className={styles.acpGrid}>
      <div className="container">
        <ul className={styles.list}>
          {entries.map((entry: OrganizationsItemType) => {
            return (
              <li key={entry.uid}>
                <ACP.Entry bestPractice={bestPractice} entry={entry} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;
};

export default Grid;
