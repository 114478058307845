import React from "react";
import { DeepPartial, UnpackNestedValue } from "react-hook-form";

import { AnswerType, QuestionType } from "components/Question/types";
import { ButtonStyle, ButtonTarget } from "components/Button/constants";
import { Model } from "./model";
import { useDataLayer } from "modules/gtm";
import { useSendFormData } from "modules/sendFormData";
import * as Button from "components/Button";
import * as Hyperlink from "components/Hyperlink";
import Questions from "components/Questions";
import Question from "components/Question";
import RecaptchaMessage from "components/RecaptchaMessage";
import yup from "modules/validation";

type ComponentProps = {
  companyName: string;
  defaultValues: UnpackNestedValue<DeepPartial<Model>>;
  formType: string;
  id: string;
  modal?: React.MutableRefObject<string | null>;
  model: Record<string, QuestionType<Model>>;
  schema?: yup.ObjectSchema;
};
function AcpQuestions({ companyName, defaultValues, formType, id, modal, model, schema }: ComponentProps) {
  const { handleSubmit, isSubmitting } = useSendFormData({
    formType,
    dataLayer: () =>
      useDataLayer({
        action: "Contact Form Submit",
        category: "Find a Consulting Provider",
        label: `${companyName}`,
      }),
    modal,
    organisationId: id,
  });
  return (
    <Questions defaultValues={defaultValues} id={formType} onSubmit={handleSubmit} schema={schema}>
      {({ control, isFormSubmitting }) => (
        <>
          <Question control={control} label="Name" question={model.name} isSubmitting={isSubmitting} />
          <Question control={control} label="Email" question={model.email} isSubmitting={isSubmitting} />
          <Question
            control={control}
            isSubmitting={isFormSubmitting || isSubmitting}
            label="Contact Number"
            question={model.contactNumber}
          />
          <Question
            control={control}
            isSubmitting={isFormSubmitting || isSubmitting}
            label="Enquiry"
            question={model.enquiry}
            type={AnswerType.TEXTAREA}
          />
          <Question
            control={control}
            isSubmitting={isFormSubmitting || isSubmitting}
            label={`I consent to these details being passed to ${companyName} in accordance with the Axelos`}
            question={model.consent}
            type={AnswerType.CHECKBOX}
          >
            &nbsp;
            <Hyperlink.CustomLink target={ButtonTarget.BLANK} href="/privacy/">
              privacy policy
            </Hyperlink.CustomLink>
            .
          </Question>
          <div>
            <RecaptchaMessage />
            <Button.Submit
              disabled={isFormSubmitting || isSubmitting}
              label="Send"
              isSubmitting={isFormSubmitting || isSubmitting}
              style={ButtonStyle.BRAND_BLACK_GRAPE}
            />
          </div>
        </>
      )}
    </Questions>
  );
}

export default React.memo(AcpQuestions) as typeof AcpQuestions;
