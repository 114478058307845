import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { useDropdown } from "components/ACP/hooks/dropdown";
import { useInputChanges } from "components/ACP/hooks/inputChanges";
import { useResetFilter } from "components/ACP/hooks/resetFilters";
import { useParams } from "components/ACP/hooks/params";
import { useValidateFilters } from "components/ACP/hooks/validateFilters";
import { useFetchACPData } from "components/ACP/hooks/fetchAcpData";
import { useFetchCountriesData } from "components/ACP/hooks/fetchCountriesData";
import { OrganizationsItemType } from "modules/api/endpoints/organizations";
import { CountriesItemType } from "modules/api/endpoints/countries";
import { ACPFilterPreviewProps } from "components/ACP/Filter/types";
import { defaultValues, model, schema } from "components/ACP/model";
import { HeroBannerProps, FilterConfigurationProps, BestPracticeType } from "components/ACP/types";

import * as ACP from "components/ACP";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import { DefaultMetaType, MetaType } from "modules/meta/types";
import { useGtmTrigger } from "components/ACP/hooks/gtmTrigger";
import { NewsletterPopupType } from "modules/newsletter/types";

export type ACPQueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  contentstackAcpListPage: {
    meta: MetaType;
    newsletter: {
      show_popup_page: string;
    };
    hero_banner: HeroBannerProps;
    filterConfiguration: FilterConfigurationProps;
  };
  allContentstackResourceHubBestPractices: {
    edges: {
      node: {
        colour: string;
        id: string;
        title: string;
      };
    }[];
  };
};

type ACPPageProps = { data: ACPQueryTypes; location: any };

const ACPPage: React.FC<ACPPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackAcpListPage;
  /* Ajax Data */
  const [ACPEntries, setACPEntries] = useState<OrganizationsItemType[] | null>([]);
  const maturityModelsEntries = [{ title: "P3M3" }, { title: "ITIL" }];
  const [countryEntries, setCountryEntries] = useState<CountriesItemType[] | null>([]);
  /* Input Filters */
  const [name, setName] = useState("");
  const nameRef = useRef<HTMLInputElement>(null);
  /* Dropdown Filter */
  const [bestPractice, setBestPractice] = useState<BestPracticeType[]>([]);
  const [activeBestPractice, setActiveBestPractice] = useState<string[]>([]);

  const maturityModels = maturityModelsEntries
    .map((i: any) => i)
    .sort((a: any, b: any) => a.title.localeCompare(b.title));
  const [activeMaturityModels, setActiveMaturityModels] = useState<string[]>([]);

  const [country, setCountry] = useState<CountriesItemType[]>([]);
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [activeCountry, setActiveCountry] = useState<string[]>([]);
  const countryButtonRef = useRef<HTMLButtonElement>(null);
  const countryDropdownRef = useRef<HTMLDivElement>(null);

  /* Pagination Control */
  const [counter, updateCounter] = useState(0);
  const [records, setRecords] = useState(6);
  const [offset, setOffset] = useState(0);
  const [reset, setReset] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number | null>(null);
  /* Search Button */
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const [searchClicked, setSearchClicked] = useState(false);
  /* Ajax States */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  /* Validation Messages */
  const emptyMessage = data?.contentstackAcpListPage?.filterConfiguration?.noResultsMessage;
  /* Results Ref */
  const resultsRef = useRef<HTMLDivElement>(null);

  /* Input Handlers */
  const { handleNameChange } = useInputChanges({ setName });

  /* Dropdown Handlers */
  const { handleCountryDropdown, handleActiveFilteredItems } =
    useDropdown({
      data,
      setActiveBestPractice,
      setBestPractice,
      countryEntries,
      setActiveMaturityModels,
      setActiveCountry,
      setCountry,
      setCountryDropdownOpen,
      countryButtonRef,
      countryDropdownOpen,
      countryDropdownRef,
    });

  useEffect(() => {
    useFetchCountriesData({ setCountryEntries });
  }, []);

  /* API Parameters */
  const params = useParams({
    activeBestPractice,
    activeMaturityModels,
    activeCountry,
    name,
    records,
    offset,
  });

  /* Filter Validation */
  const { validate } = useValidateFilters({
    name,
    activeBestPractice,
    activeMaturityModels,
    activeCountry,
    setIsLoading,
    setTotalRecords,
    setACPEntries,
    setErrorMessage,
    emptyMessage,
    setSkeleton,
  });

  /* Search Button Handler */
  const handleSearchButtonClick = () => {
    setSearchClicked(true);
    setSkeleton(true);
  };

  useEffect(() => {
    if (!searchClicked) return;
    if (validate() && reset) {
      useGtmTrigger({ activeCountry, activeBestPractice, activeMaturityModels, countryEntries, name });
      const fetchPaginatedData = false;
      useFetchACPData({
        params,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setACPEntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [searchClicked, reset]);

  /* Load More Button Handler */
  const handleLoadMoreClick = () => {
    setSearchClicked(false);
    updateCounter((currCounter) => currCounter + 1);
  };

  /* Load Paginated Results */
  useEffect(() => {
    if (counter === 0) return;
    if (validate()) {
      const fetchPaginatedData = true;
      useFetchACPData({
        params,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setACPEntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [counter]);

  // /* Reset Filter On Enter/Click With Filter Controls */
  useResetFilter({
    setRecords,
    setOffset,
    nameRef,
    searchButtonRef,
    setReset,
  });

  // Focus On Results
  useLayoutEffect(() => {
    if (!isLoading && !searchClicked) return;
    if (isLoading && searchClicked && resultsRef && resultsRef.current) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
      });
      setSearchClicked(false);
    }
  }, [isLoading, resultsRef, searchClicked]);

  /* Newsletter */
  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  const acp = data?.contentstackAcpListPage;

  const filterProps: ACPFilterPreviewProps = {
    activeBestPractice: activeBestPractice,
    activeCountry: activeCountry,
    activeMaturityModels: activeMaturityModels,
    country: country,
    countryButtonRef: countryButtonRef,
    countryDropdownOpen: countryDropdownOpen,
    countryDropdownRef: countryDropdownRef,
    handleActiveFilteredItems: handleActiveFilteredItems,
    handleCountryDropdown: handleCountryDropdown,
    handleNameChange: handleNameChange,
    handleSearchButtonClick: handleSearchButtonClick,
    maturityModels: maturityModels,
    name: name,
    nameRef: nameRef,
    searchButtonRef: searchButtonRef,
  };

  return (
    <ACP.Template
      acpEntries={ACPEntries}
      bestPractice={bestPractice}
      defaultMeta={data.contentstackGlobalMeta}
      defaultValues={defaultValues}
      errorMessage={errorMessage}
      filterProps={filterProps}
      handleLoadMoreClick={handleLoadMoreClick}
      hero_banner={acp?.hero_banner}
      isLoading={isLoading}
      location={location}
      meta={acp?.meta}
      model={model}
      resultsRef={resultsRef}
      schema={schema}
      skeleton={skeleton}
      totalRecords={totalRecords}
    />
  );
};

export const query = graphql`
  query ($id: String) {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackAcpListPage(id: { eq: $id }) {
      ...metaAcpList
      newsletter {
        show_popup_page
      }
      hero_banner {
        content
        image {
          url
        }
        image_horizontal_position
      }
      filterConfiguration: filter_configuration {
        noResultsMessage: no_results_message
      }
    }
    allContentstackResourceHubBestPractices {
      edges {
        node {
          id
          colour
          title
          acp
        }
      }
    }
  }
`;

export default ACPPage;
