import React, { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import clsx from "clsx";

import { ACPTier } from "../constants";
import { BestPracticeType } from "../types";
import { ButtonTarget } from "components/Button/constants";
import { addProtocol, hasStartAndEndTag, htmlSafe } from "modules/utils";
import { defaultValues, model, schema } from "components/ACP/model";
import { ModalType } from "modules/modals/constants";
import { OrganizationsItemType } from "modules/api/endpoints/organizations";
import { useDataLayer } from "modules/gtm";
import * as Button from "components/Button";
import AcpQuestions from "components/ACP/Questions";
import LazyloadImage from "modules/lazyload/Image";
import PersonSVG from "modules/theme/icons/general/person.svg";
import useModalAction from "modules/modals/hooks/useModalAction";

import * as styles from "./styles.module.scss";

type ACPEntryProps = {
  bestPractice: BestPracticeType[];
  entry: OrganizationsItemType;
};

type AllCompanyLogosProps = {
  allContentstackCompanyLogos: {
    edges: {
      node: {
        alt_text?: string;
        logo: {
          title: string;
          url: string;
        };
        uid: string;
      };
    }[];
  };
};

const Entry: React.FC<ACPEntryProps> = ({ bestPractice, entry }) => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const data: AllCompanyLogosProps = useStaticQuery(graphql`
    {
      allContentstackCompanyLogos {
        edges {
          node {
            logo {
              url
              title
            }
            uid
            alt_text
          }
        }
      }
    }
  `);
  const companyLogos = data.allContentstackCompanyLogos.edges;

  const handleClick = () => {
    if (!modal.current) {
      modal.current = modalShow({
        onCancel: () => {
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        renderBodyContent: () => (
          <AcpQuestions
            companyName={entry.name}
            defaultValues={defaultValues}
            formType="ACP"
            id={entry.uid}
            modal={modal}
            model={model}
            schema={schema}
          />
        ),
        renderHeaderContent: () => (
          <h2>
            Contact <span>{entry.name}</span>
          </h2>
        ),
        type: ModalType.FORM,
      });
    }
  };

  const logo = entry.logo && companyLogos.map((i) => i.node).find((i) => i.uid === entry.logo[0]?.uid);
  const alt = logo?.alt_text ? logo?.alt_text : logo?.logo.title;
  const maturityModelTypesExists = entry?.maturityModelTypes.length !== 0;
  const bestPracticesExists = entry?.bestPractices.length !== 0 && entry.bestPractices[0] !== "";
  const assessorsExists = entry.assessors && entry.assessors.length > 0;
  const itilAssessors = entry.assessors && entry.assessors.filter((i) => i.assessor.itil);
  const p3m3Assessors = entry.assessors && entry.assessors.filter((i) => i.assessor.p3m3);
  const itilAssessorExists = itilAssessors && itilAssessors.length > 0;
  const p3m3AssessorExists = p3m3Assessors && p3m3Assessors.length > 0;

  const renderContent = entry.short_description && htmlSafe(entry.short_description);
  const safeContent =
    entry.short_description && hasStartAndEndTag(entry.short_description) ? renderContent : <p>{renderContent}</p>;

  const className = clsx(styles.acpEntry, styles[`${entry.acp_tier}`]);

  return (
    <div className={className}>
      <header>
        <p>{entry.name}</p>
        {logo &&
          (entry.website ? (
            <LazyloadImage
              alt={alt}
              ariaLabel={`Visit ${entry.name}`}
              icon
              onClick={() => window.open(entry.website, "_newtab")}
              role="button"
              src={logo?.logo?.url}
              tagName="img"
            />
          ) : (
            <LazyloadImage alt={alt} icon src={logo?.logo?.url} tagName="img" />
          ))}
      </header>
      {(maturityModelTypesExists || bestPracticesExists) && (
        <div>
          {maturityModelTypesExists && (
            <>
              <p>Maturity models</p>
              <ul>
                {entry?.maturityModelTypes.map((i: any, key: any) => {
                  const maturityClassName = clsx(i === "P3M3" && styles.Maturity, i === "ITIL" && styles.ITIL);
                  return (
                    <li key={key} className={maturityClassName}>
                      <span>{i} Maturity Model</span>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          {bestPracticesExists && (
            <>
              <p>Best practice</p>
              <ul>
                {entry?.bestPractices.map((i: any, key: any) => {
                  const bestPracticesColour = bestPractice.find((item: any) => item.title === i)?.colour;
                  return (
                    <li key={key} className={styles[`${bestPracticesColour}`]}>
                      <span>{i}</span>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      )}
      <footer>
        {entry.short_description && safeContent}
        {assessorsExists && (
          <div>
            <PersonSVG />
            {entry.assessors !== undefined && (
              <p>
                {entry.assessors.length} Assessor{entry.assessors.length === 1 ? "" : "s"}
              </p>
            )}
            <ul>
              {itilAssessorExists && (
                <li>
                  <span>ITIL</span>:&nbsp;
                  {entry.assessors &&
                    entry.assessors
                      .filter((i) => i.assessor.itil)
                      .map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item.assessor.name}
                            {entry.assessors && index !== entry.assessors.filter((i) => i.assessor.itil).length - 1
                              ? ", "
                              : ""}
                          </React.Fragment>
                        );
                      })}
                </li>
              )}
              {p3m3AssessorExists && (
                <li>
                  <span>P3M3</span>:&nbsp;
                  {entry.assessors &&
                    entry.assessors
                      .filter((i) => i.assessor.p3m3)
                      .map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item.assessor.name}
                            {entry.assessors && index !== entry.assessors.filter((i) => i.assessor.p3m3).length - 1
                              ? ", "
                              : ""}
                          </React.Fragment>
                        );
                      })}
                </li>
              )}
            </ul>
          </div>
        )}
        <ul className={clsx(styles.buttons, entry.website && styles.hasWebsite)}>
          {entry.acp_tier === ACPTier.ELITE && entry.slug && (
            <li className={styles.profile}>
              <Button.PageLink
                label="Profile"
                onClick={() => {
                  useDataLayer({
                    action: "Profile Button Click",
                    category: "Find a Consulting Provider",
                    label: `${entry.name}`,
                  });
                }}
                slug={entry.slug}
              />
            </li>
          )}
          <li>
            <Button.Generic
              label="Contact"
              onClick={() => {
                useDataLayer({
                  action: "Contact Button Click",
                  category: "Find a Consulting Provider",
                  label: `${entry.name}`,
                });
                return handleClick();
              }}
            />
          </li>
          {entry.website && (
            <li>
              <Button.CustomLink
                label="Website"
                href={entry.website}
                onClick={() => {
                  useDataLayer({
                    action: "ATO Website Click",
                    category: "Find a Consulting Provider",
                    label: addProtocol(entry.website),
                  });
                }}
                target={ButtonTarget.BLANK}
              />
            </li>
          )}
        </ul>
      </footer>
    </div>
  );
};

export default Entry;
