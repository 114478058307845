import React from "react";
import clsx from "clsx";

import * as gridStyles from "../Grid/styles.module.scss";
import * as entryStyles from "../Entry/styles.module.scss";
import * as styles from "./styles.module.scss";

type ATOSkeletonProps = {};

const Skeleton: React.FC<ATOSkeletonProps> = () => {
  const className = clsx(styles.acpSkeleton, entryStyles.acpEntry);
  return (
    <div className={gridStyles.acpGrid}>
      <div className="container">
        <ul className={gridStyles.list}>
          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
                <p></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <div className={styles.shimmer}></div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
                <ul className={entryStyles.buttons}>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>
          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
                <p></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <div className={styles.shimmer}></div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
                <ul className={entryStyles.buttons}>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>
          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
                <p></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <div className={styles.shimmer}></div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
                <ul className={entryStyles.buttons}>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>
          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
                <p></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <div className={styles.shimmer}></div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
                <ul className={entryStyles.buttons}>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>
          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
                <p></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <div className={styles.shimmer}></div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
                <ul className={entryStyles.buttons}>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>
          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
                <p></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <div className={styles.shimmer}></div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
                <ul className={entryStyles.buttons}>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Skeleton;
