type useParamsProps = {
  activeBestPractice: string[];
  activeMaturityModels: string[];
  activeCountry: string[];
  name: string;
  offset: number;
  records: number;
  type?: string;
};

export const useParams = ({
  activeBestPractice,
  activeMaturityModels,
  activeCountry,
  name,
  records,
  offset,
}: useParamsProps) => {
  return {
    name: name ? name : null,
    bestPractices: !!activeBestPractice.length ? activeBestPractice.map((activeItem) => activeItem).join(",") : null,
    maturityModels: !!activeMaturityModels.length
      ? activeMaturityModels.map((activeItem) => activeItem).join(",")
      : null,
    countryId: !!activeCountry.length ? activeCountry.map((activeItem) => activeItem).join(",") : null,
    numberPerPage: records,
    offset: offset,
    type: "acp",
  };
};
