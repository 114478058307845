import { API, api } from "modules/api";
import { OrganizationsItemType, OrganizationsGetResponse } from "modules/api/endpoints/organizations";

type RecordsProps = {
  name: string | null;
  bestPractices: string | null;
  maturityModels: string | null;
  countryId: string | null;
  numberPerPage: number;
  offset: number;
};

type useFetchACPDataProps = {
  emptyMessage: string;
  fetchPaginatedData: boolean;
  params: RecordsProps;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setTotalRecords: (value: React.SetStateAction<number | null>) => void;
  setErrorMessage: (value: React.SetStateAction<string | null>) => void;
  setOffset: (value: React.SetStateAction<number>) => void;
  setACPEntries: (value: React.SetStateAction<OrganizationsItemType[] | null>) => void;
  records: number;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  setSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useFetchACPData = async ({
  emptyMessage,
  fetchPaginatedData,
  params,
  setIsLoading,
  setTotalRecords,
  setErrorMessage,
  setOffset,
  setACPEntries,
  records,
  setReset,
  setSkeleton,
}: useFetchACPDataProps) => {
  !fetchPaginatedData && setReset(false);
  try {
    const acpResponse = await api(API.GET_ORGANIZATIONS(params)).then((res: OrganizationsGetResponse) => res.data);
    if (acpResponse.records.length === 0) {
      // No records
      setIsLoading(false);
      setSkeleton(false);
      setTotalRecords(0);
      setErrorMessage(emptyMessage);
      return;
    }
    // Successful
    setIsLoading(false);
    setSkeleton(false);
    setTotalRecords(acpResponse.totalRecords);
    if (fetchPaginatedData) {
      setOffset((currOffset) => currOffset + records);
      setACPEntries((currEntries) => {
        if (currEntries) {
          return [...currEntries, ...acpResponse.records];
        }
        return acpResponse.records;
      });
    } else {
      setOffset(records);
      setACPEntries(acpResponse.records);
    }
    setErrorMessage(null);
  } catch (error) {
    // Error
    setIsLoading(false);
    setSkeleton(false);
    setTotalRecords(0);
    setErrorMessage(`${error}.`);
  }
};
