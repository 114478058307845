import { CountriesItemType } from "modules/api/endpoints/countries";
import { useDataLayer } from "modules/gtm";

type useGtmTriggerProps = {
  activeCountry: string[];
  activeBestPractice: string[];
  activeMaturityModels: string[];
  countryEntries: CountriesItemType[] | null;
  name: string;
};

export const useGtmTrigger = ({
  activeCountry,
  activeBestPractice,
  activeMaturityModels,
  countryEntries,
  name,
}: useGtmTriggerProps) => {
  const gtmCountry = countryEntries?.find((i) => i.uid === activeCountry.toString())?.name;
  const cleanName = name ? `${name}` : null;
  const cleanActiveBestPractice =
    activeBestPractice.length > 0 ? `${activeBestPractice.filter((i) => i !== null).join(", ")}` : null;
  const cleanActiveMaturityModels =
    activeMaturityModels.length > 0 ? `${activeMaturityModels.filter((i) => i !== null).join(", ")}` : null;
  const cleanActiveCountry = gtmCountry ? gtmCountry : null;
  const gtmParams = [cleanName, cleanActiveBestPractice, cleanActiveMaturityModels, cleanActiveCountry].filter(
    (i) => i !== null
  );

  return useDataLayer({
    action: "Search",
    category: "Find a Consulting Provider",
    label: gtmParams.length === 1 ? gtmParams.join("") : gtmParams.join(", "),
  });
};
