import React from "react";

import { ACPFilterProps } from "./types";
import * as Button from "components/Button";
import { ButtonStyle } from "components/Button/constants";
import * as DropdownSelect from "components/DropdownSelect";
import { DropdownSize, DropdownType } from "components/DropdownSelect/constants";
import { DropdownButtonType, DropdownMatchesType } from "components/DropdownSelect/constants";
import { DropdownItemProps } from "components/DropdownSelect/types";
import * as Input from "components/Inputs/Input";
import { InputFormat, InputStyle } from "components/Inputs/Input/constants";
import LoadingSVG from "modules/theme/icons/loading/button-loading.svg";

import * as styles from "./styles.module.scss";

const Filter: React.FC<ACPFilterProps> = ({
  activeBestPractice,
  activeCountry,
  activeMaturityModels,
  bestPractice,
  country,
  countryButtonRef,
  countryDropdownOpen,
  countryDropdownRef,
  forwardedContainerRef,
  handleActiveFilteredItems,
  handleCountryDropdown,
  handleNameChange,
  handleSearchButtonClick,
  isLoading,
  maturityModels,
  name,
  nameRef,
  searchButtonRef,
}) => {
  const handleEnterKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearchButtonClick();
    }
  };
  return (
    <div className={styles.atoFilter}>
      <div>
        <DropdownSelect.Multiple
          activeItems={activeBestPractice}
          dropdownSelector={DropdownMatchesType.TITLE}
          forwardedContainerRef={forwardedContainerRef}
          items={bestPractice}
          label="Clear"
          name="Best practice"
          setActiveItems={handleActiveFilteredItems}
          type={DropdownType.BEST_PRACTICES}
        />
        <DropdownSelect.Multiple
          activeItems={activeMaturityModels}
          appendTo=" Maturity Model"
          dropdownSelector={DropdownMatchesType.TITLE}
          forwardedContainerRef={forwardedContainerRef}
          items={maturityModels}
          label="Clear"
          name="Maturity model"
          setActiveItems={handleActiveFilteredItems}
          type={DropdownType.MATURITY_MODEL}
        />
        <DropdownSelect.Single
          activeItems={activeCountry}
          buttonType={DropdownButtonType.RADIO}
          closeOnSelection={true}
          dropdownSelector={DropdownMatchesType.UID}
          handleToggleDropdown={handleCountryDropdown}
          forwardedButtonRef={countryButtonRef}
          forwardedContainerRef={forwardedContainerRef}
          forwardedDropdownRef={countryDropdownRef}
          items={country as DropdownItemProps[]}
          label="Clear"
          name="Select a country"
          setActiveItems={handleActiveFilteredItems}
          size={DropdownSize.SMALL}
          toggleDropdown={countryDropdownOpen}
          type={DropdownType.COUNTRY}
        />
      </div>
      <div>
        <Input.String
          format={InputFormat.SEARCH}
          id="atoName"
          forwardedRef={nameRef}
          name="atoName"
          onChange={handleNameChange}
          onKeyDown={handleEnterKeyDown}
          placeholder="Or search by name"
          style={InputStyle.SECONDARY}
          value={name}
        />
        <div>
          {isLoading && (
            <div className={styles.loading}>
              <LoadingSVG />
            </div>
          )}
          <Button.Generic
            disabled={isLoading ? true : false}
            forwardedRef={searchButtonRef}
            label="Search"
            onClick={handleSearchButtonClick}
            style={ButtonStyle.BRAND_BERRY}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
