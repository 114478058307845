import { DropdownType } from "components/DropdownSelect/constants";
import { CountriesItemType } from "modules/api/endpoints/countries";
import { ACPQueryTypes } from "pages/{ContentstackAcpListPage.url}";
import { useEffect } from "react";
import { BestPracticeType } from "../types";

type useDropdownProps = {
  data: ACPQueryTypes;
  setActiveBestPractice: (value: React.SetStateAction<string[]>) => void;
  setBestPractice: (value: React.SetStateAction<BestPracticeType[]>) => void;
  setActiveMaturityModels: (value: React.SetStateAction<string[]>) => void;
  setActiveCountry: (value: React.SetStateAction<string[]>) => void;
  setCountryDropdownOpen: (value: React.SetStateAction<boolean>) => void;
  setCountry: (value: React.SetStateAction<CountriesItemType[]>) => void;
  countryButtonRef: React.RefObject<HTMLButtonElement>;
  countryDropdownOpen: boolean;
  countryDropdownRef: React.RefObject<HTMLDivElement>;
  countryEntries: CountriesItemType[] | null;
};

export const useDropdown = ({
  data,
  setActiveBestPractice,
  setBestPractice,
  setActiveMaturityModels,
  setActiveCountry,
  setCountryDropdownOpen,
  setCountry,
  countryButtonRef,
  countryDropdownOpen,
  countryDropdownRef,
  countryEntries,
}: useDropdownProps) => {
  useEffect(() => {
    if (!data) return;
    setBestPractice(() => {
      return data.allContentstackResourceHubBestPractices.edges
        .filter((item: any) => item.node.acp)
        .map((item: any) => item.node)
        .sort((a: any, b: any) => a.title.localeCompare(b.title));
    });
  }, [data]);

  useEffect(() => {
    if (!countryEntries) return;
    setCountry(() => {
      return countryEntries
        .map((item) => {
          item.title = item.name;
          return item;
        })
        .sort((a, b) => {
          return a.title && b.title ? a.title.localeCompare(b.title) : 0;
        });
    });
  }, [countryEntries]);

  const getActiveIds = (arr: string[], id: string) => {
    if (!id) return [];
    if (arr.includes(id)) {
      return arr.filter((activeId) => activeId !== id);
    }
    return [...arr, id];
  };

  const handleActiveFilteredItems = async (id: string, type: DropdownType) => {
    if (type === DropdownType.BEST_PRACTICES) {
      setActiveBestPractice((activeIds) => {
        return getActiveIds(activeIds, id);
      });
    }
    if (type === DropdownType.MATURITY_MODEL) {
      setActiveMaturityModels((activeIds) => {
        return getActiveIds(activeIds, id);
      });
    }
    if (type === DropdownType.COUNTRY) {
      setActiveCountry((activeIds) => {
        return getActiveIds(activeIds, id);
      });
    }
  };

  const handleCountryDropdown = () => {
    if (countryDropdownOpen) {
      setCountryDropdownOpen(false);
    } else {
      setCountryDropdownOpen(true);
    }
  };

  useEffect(() => {
    const closeFilterOnClickOutside = (event: any) => {
      if (
        countryButtonRef.current &&
        !countryButtonRef.current.contains(event.target) &&
        countryDropdownRef.current &&
        !countryDropdownRef.current.contains(event.target)
      ) {
        setCountryDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", closeFilterOnClickOutside);
    return () => {
      document.removeEventListener("mousedown", closeFilterOnClickOutside);
    };
  }, [
    countryButtonRef,
    countryDropdownRef,
  ]);

  return {
    handleCountryDropdown,
    handleActiveFilteredItems,
  };
};
