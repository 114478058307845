import { useEffect } from "react";

type useResetFilterProps = {
  setRecords: (value: React.SetStateAction<number>) => void;
  setOffset: (value: React.SetStateAction<number>) => void;
  nameRef: React.RefObject<HTMLInputElement>;
  searchButtonRef: React.RefObject<HTMLButtonElement>;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useResetFilter = ({ setRecords, setOffset, nameRef, searchButtonRef, setReset }: useResetFilterProps) => {
  const resetFilters = () => {
    setRecords(6);
    setOffset(0);
    setReset(true);
  };
  useEffect(() => {
    nameRef &&
      nameRef.current &&
      nameRef.current.addEventListener("keydown", (event) => {
        event.key === "Enter" && resetFilters();
      });
    return () => {
      nameRef &&
        nameRef.current &&
        nameRef.current.addEventListener("keydown", (event) => {
          event.key === "Enter" && resetFilters();
        });
    };
  }, [nameRef]);

  useEffect(() => {
    searchButtonRef && searchButtonRef.current && searchButtonRef.current.addEventListener("click", resetFilters);
    return () => {
      searchButtonRef && searchButtonRef.current && searchButtonRef.current.addEventListener("click", resetFilters);
    };
  }, [searchButtonRef]);
};
