import { OrganizationsItemType } from "modules/api/endpoints/organizations";

type useValidateFiltersProps = {
  activeBestPractice: string[];
  activeMaturityModels: string[];
  activeCountry: string[];
  name: string;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setTotalRecords: (value: React.SetStateAction<number | null>) => void;
  setACPEntries: (value: React.SetStateAction<OrganizationsItemType[] | null>) => void;
  setErrorMessage: (value: React.SetStateAction<string | null>) => void;
  emptyMessage: string;
  setSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useValidateFilters = ({
  activeBestPractice,
  activeMaturityModels,
  activeCountry,
  name,
  setIsLoading,
  setTotalRecords,
  setACPEntries,
  setErrorMessage,
  emptyMessage,
  setSkeleton,
}: useValidateFiltersProps) => {
  const validate = () => {
    setIsLoading(true);
    if (!name && activeBestPractice.length === 0 && activeMaturityModels.length === 0 && activeCountry.length === 0) {
      // No filter options set
      setTotalRecords(0);
      setACPEntries(null);
      setErrorMessage(emptyMessage);
      setTimeout(() => {
        setSkeleton(false);
        setIsLoading(false);
      });
      return false;
    }
    return true;
  };

  return { validate };
};
